.carousel .slick-list {
    text-align: left;
  }
  
  .carousel .slick-track {
    display: flex !important;
    justify-content: flex-start;
  }
  
  .carousel .slick-slide {
    float: none !important;
    display: inline-block;
  }
  